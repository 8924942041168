
  import { defineComponent } from 'vue'
  import { arrowBack } from 'ionicons/icons'
  import { mapGetters } from 'vuex'
  import wAxios from '@/plugins/w/axios'
  import ZSummary from '@/plugins/app/_components/z-summary.vue'

  const api = {
    country: (id) => wAxios.get_auth(`v1/countries/${id}`)
  }

  export default defineComponent({

    watch: {
      $route (to, from){
        const fromPinDetail = from.fullPath.includes('pin-detail')
        const fromCountryList = from.fullPath.includes('country-list')
        const toCountryDetail = to.fullPath.includes('country-detail')

        if (fromPinDetail && toCountryDetail) {
          return
        }

        if(fromCountryList && toCountryDetail) {
          this._fetchCountry()
        }

        // if (this.$route.params.id && this.$route.fullPath.includes('country-detail')){
        //   this._fetchCountry()
        // }
      }
    },

    async created() {
      await this._fetchCountry()
    },

    data() {
      return {
        arrowBack,
        country: {}
      }
    },

    computed: {
      ...mapGetters('countries', ['pins', 'isLoading'])
    },

    components: {
      ZSummary
    },

    methods: {
      async _fetchCountry(){
        try {
          this.country = await api.country(this.$route.params.id)
          await this.$store.dispatch(`countries/fetchByPage`, { country: this.country.id, page:1})
        } catch (err) {
          console.error(err)
        }
      },
       isEmpty(obj) {
        return Object.keys(obj).length === 0
      },
    }
  })
